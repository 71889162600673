import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Multi = ({
  data, name, children, save, textMore, textLess, control
}) => {
  const [qtd, setQtd] = useState(data?.[name]?.length || 1);
  const [on, setOn] = useState(control);
  const childs = [];
  const saveMulti = (e) => {
    const arr = data[name] || [];
    arr[e.target.getAttribute('index')] = arr[e.target.getAttribute('index')] || {};
    arr[e.target.getAttribute('index')][e.target.name] = e.target.value;
    save({ target: { name, value: arr } });
  };
  useEffect(() => {
    if (control !== on) {
      if (control === 0) {
        setQtd(1);
        save({ target: { name, value: [] } });
      }
      setOn(control);
    }
  });
  for (let i = 0; i < qtd; i++) {
    childs.push(React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          index: i,
          key: child.name + i,
          value: (data[name]?.[i]?.[child.props.name]),
          change: saveMulti
        });
      }
      return undefined;
    }));
  }
  const remove = () => {
    setQtd(qtd - 1);
    const arr = data[name] || [];
    if (arr.length === qtd) arr.pop();
    save({ target: { name, value: arr } });
  };
  if (control && control === 1) {
    return (
      <div className="Multi">
        {childs}
        <a onClick={() => { setQtd(qtd + 1); }} onKeyPress={() => { setQtd(qtd + 1); }} role="button" tabIndex={0}>{textMore}</a>
        {qtd > 1 ? <a onClick={remove} onKeyPress={remove} role="button" tabIndex={0}>{textLess}</a> : ''}
      </div>
    );
  }
  return '';
};

Multi.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.any,
  save: PropTypes.func,
  textMore: PropTypes.string,
  textLess: PropTypes.string,
  control: PropTypes.any
};

export default Multi;
