import PropTypes from 'prop-types';

function Perg({
  mask,
  change,
  cols,
  type,
  index,
  name,
  grow,
  value,
  children,
  label,
  options
}) {
  const masker = (e) => {
    let tel = e.target.value;
    switch (mask) {
      case 'phone':
        tel = tel.replace(/\D/g, '');
        tel = tel.replace(/^(\d)/, '($1');
        tel = tel.replace(/(.{3})(\d)/, '$1) $2');
        if (tel.length === 10) {
          tel = tel.replace(/(.{1})$/, '-$1');
        } else if (tel.length === 11) {
          tel = tel.replace(/(.{2})$/, '-$1');
        } else if (tel.length === 12) {
          tel = tel.replace(/(.{3})$/, '-$1');
        } else if (tel.length === 13) {
          tel = tel.replace(/(.{4})$/, '-$1');
        } else if (tel.length === 14) {
          tel = tel.replace(/(.{8})$/, ' $1').replace(/(.{4})$/, '-$1');
        } else if (tel.length > 14) {
          tel = tel.replace(/(.{9})$/, ' $1').replace(/(.{5})$/, '-$1').substr(0, 16);
        }
        e.target.value = tel;
        break;
      default:
        break;
    }
  };
  const changer = (e) => {
    if (mask) masker(e);
    change(e);
  };
  const viewMoney = (val) => `R$ ${((val || 0) ? (val.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.')) : '0,00')}`;
  const saveMoney = (e) => {
    const val = parseFloat(e.target.value.replace(/\D/g, '')) / 100;
    change({ target: { name: e.target.name, value: val } });
  };
  const width = (cols && cols > 1) ? `calc(${(100 / cols)}% - 15px)` : '100%';
  const makeInput = () => {
    let input = null;
    if (type === 'area') {
      input = <textarea index={index} name={name} onChange={changer} value={value || ''} />;
    } else if (type === 'opt') {
      input = [];
      for (let i = 0; i < options.length; i++) {
        const opt = options[i];
        input.push(
          <div key={`opt${name + input.length}`} className="radio-opt" style={grow ? { flex: 1 } : {}}>
            <input index={index} name={name} type="radio" id={opt.key} value={opt.value} onChange={changer} checked={`${opt.value}` === `${value}`} />
            <label htmlFor={opt.key}>{opt.label}</label>
          </div>
        );
      }
      input = <div className="radio-opt-wrapper">{input}</div>;
    } else if (type === 'money') {
      input = <input index={index} name={name} type="text" value={viewMoney(value)} onChange={saveMoney} />;
    } else if (type === 'static') {
      input = <input index={index} name={name} type="text" value={value || ''} onChange={changer} />;
    } else {
      input = <input index={index} name={name} type={type || 'text'} value={value || ''} onChange={changer} />;
    }
    return input;
  };
  if (type === 'inline') {
    return (
      <div className="Perg inline" style={{ width }}>
        <span className="label">{children}</span>
      </div>
    );
  }
  if (!label) {
    return makeInput();
  }
  return (
    <div className="Perg" style={{ width }}>
      <span className="label">{label}</span>
      {makeInput()}
    </div>
  );
}

Perg.propTypes = {
  mask: PropTypes.string,
  change: PropTypes.func,
  cols: PropTypes.any,
  type: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  grow: PropTypes.bool,
  value: PropTypes.any,
  children: PropTypes.any,
  label: PropTypes.any,
  options: PropTypes.any
};

export default Perg;
