import PropTypes from 'prop-types';
import Perg from '../Perg';
import Multi from '../Multi';
import Cell from '../Cell';
import EducTable from '../EducTable';

const Page = ({ data, saveValue }) => {
  const saveEduc = (i, val) => {
    const edu = (data?.educacao_filhos || []).slice();
    edu[i] = val;
    saveValue({ target: { name: 'educacao_filhos', value: edu } });
  };
  return (
    <section>
      <div className="formgroup">
        <h2>Confidencial</h2>
        <Perg label="E-mail" type="email" name="email" value={data.email} cols="2" change={saveValue} />
        <Perg label="Qual sua data de nascimento?" type="date" name="data_nascimento" value={data.data_nascimento} cols="2" change={saveValue} />
        <Perg
          type="opt"
          label="É casado(a)?"
          name="casado"
          value={data.casado}
          options={[
            { key: 'solteiro', value: 'solteiro', label: 'Solteiro' },
            { key: 'comunhaoPar', value: 'comunhao_parcial', label: 'Comunhão parcial' },
            { key: 'comunhaoTot', value: 'comunhao_total', label: 'Comunhão total' },
            { key: 'uniaoEs', value: 'uniao_estavel', label: 'União estável' },
            { key: 'separacaoTot', value: 'separacao_total', label: 'Separação total' }
          ]}
          cols="1"
          change={saveValue}
          grow
        />
        <Perg label="Recomendado por:" name="recomendado" value={data.recomendado} cols="2" change={saveValue} />
        <Perg label="Qual é a data comemorativa do casamento?" type="date" name="data_casamento" value={data.data_casamento} cols="2" change={saveValue} />
        <Perg label="Qual o nome e a data comemorativa do cônjuge?" name="nome_conjuge" value={data.nome_conjuge} cols="2" change={saveValue} />
        <Perg label="&nbsp;" type="date" name="data_conjuge" value={data.data_conjuge} cols="2" change={saveValue} />
      </div>
      <hr />
      <div className="formgroup">
        <Perg
          type="opt"
          label="Tem dependentes econômicos?"
          name="dependentes_tem"
          value={data.dependentes_tem}
          options={[
            { key: 'dependentes_temS', value: 1, label: 'Sim' },
            { key: 'dependentes_temN', value: 0, label: 'Não' }
          ]}
          cols="1"
          change={saveValue}
        />
        <Multi
          data={data}
          name="dependentes"
          save={saveValue}
          control={data.dependentes_tem}
          init={{ dependente_nome: (data.depentente_nome || []), dependente_idade: (data.dependente_idade || []), dependente_parentesco: (data.dependente_parentesco || []) }}
          textMore="+ Adicionar mais um dependente"
          textLess="- Remover um dependente"
        >
          <Perg label="Nome:" name="nome" cols="1.8" />
          <Perg label="Idade:" type="number" name="idade" cols="5.4" />
          <Perg label="Parentesco:" name="parentesco" cols="3.4" />
        </Multi>
      </div>
      <hr />
      <div className="formgroup">
        <h2>Educação dos filhos</h2>
        <Multi
          control={1}
          data={data}
          name="educacao_filhos"
          cols="1"
          save={(a, b, c) => { console.log(a, b, c); }}
          textMore="+ Adicionar a educação de mais um filho"
          textLess="- Remover último item"
        >
          <EducTable save={saveEduc} data={data.educacao_filhos} />
        </Multi>
        <Cell
          label="Total Despesas com Educação"
          value={data?.educacao_filhos?.reduce((a, v) => a + (v?.total?.custo || 0), 0) || 0}
          cols={2}
          money
        />
        <Cell
          label="Maior período para formação (anos)"
          value={data?.educacao_filhos?.reduce((a, v) => ((a > (v?.total?.anos || 0)) ? a : v.total.anos), 0) || 0}
          cols={2}
        />
        <Perg label="Em uma escala de 0 a 10, qual a nota de importância que você daria para a garantia da educação de seus filhos?" name="educacao_filhos_importancia" value={data.educacao_filhos_importancia} type="number" cols="1" change={saveValue} />
        <Perg type="opt" label="Você tem alguma reserva para isso hoje?" name="educacao_filhos_reserva" value={data.educacao_filhos_reserva} options={[{ key: 'educacao_filhos_reservaS', value: 1, label: 'Sim' }, { key: 'reservaN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
        {data?.educacao_filhos_reserva === 1 ? <Perg label="Quanto?" type="money" name="educacao_filhos_quanto_reserva" value={data.educacao_filhos_quanto_reserva} cols="3" change={saveValue} /> : ''}
        <Perg label="Se ontem sua vida tivesse sido interrompida por um acidente ou uma doença, como sua família arcaria com essas despesas a partir de hoje?" type="area" name="como_fam_arca" value={data.como_fam_arca} cols="1" change={saveValue} />
        <Perg label="Observação" type="area" value={data.pg3_obs} name="pg3_obs" change={saveValue} />
      </div>
    </section>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
