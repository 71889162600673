import PropTypes from 'prop-types';
import Perg from './Perg';

const Cell = ({
  name, value, save, label, col, cols, money, style
}) => {
  let width = 'auto';
  if (col) width = ((cols && cols > 1) ? `calc(${(100 / cols)}% - 15px)` : '100%');
  return (
    <div className={`Cell ${(col ? 'col' : '')}`} style={{ ...style, width }}>
      <label htmlFor={name}>{label}</label>
      <div className="lt-val"><Perg id={name} type={money ? 'money' : 'static'} name={name} change={save || (() => {})} value={value} /></div>
    </div>
  );
};

Cell.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  save: PropTypes.func,
  label: PropTypes.string,
  col: PropTypes.any,
  cols: PropTypes.number,
  money: PropTypes.bool,
  style: PropTypes.object
};

export default Cell;
