import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';

const calcExpira = (user) => {
  const dtIni = moment(user.data_inicio);
  if (user.plano_id === 1) {
    const expireDate = dtIni.add(30, 'days');
    return `${expireDate.diff(moment()) >= 0 ? 'Expira' : 'Expirou'} ${expireDate.fromNow()}`;
  }
  return '';
};

const AccountProfile = ({ user, ...props }) => (
  <Card {...props}>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography
          color="textSecondary"
          variant="body1"
        >
          Plano:
        </Typography>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h3"
        >
          {user.plano?.plano}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {calcExpira(user)}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button
        color="primary"
        fullWidth
        variant="text"
      >
        Mudar plano
      </Button>
    </CardActions>
  </Card>
);

AccountProfile.propTypes = {
  user: PropTypes.any.isRequired
};

export default AccountProfile;
