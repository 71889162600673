import PropTypes from 'prop-types';
import Perg from './Perg';

const Rendimentos = ({ data, save }) => {
  const cols = [{
    name: 'titular',
    text: 'Titular',
    value: (data?.titular || {}),
  },
  {
    name: 'conjuge',
    text: 'Cônjuge',
    value: (data?.conjuge || {}),
  }];
  const saveValue = (e) => {
    const obj = { ...data };
    const val = e.target.value;
    const [c, name] = e.target.name.split('.');

    obj[c] = obj[c] || {};
    obj[c][name] = val;

    if (name === 'mensal') {
      obj.total = Object.keys(obj || {}).reduce((a, v) => {
        if (v === 'total') return a;
        return a + (obj[v]?.mensal || 0);
      }, 0);
    }

    if (save) save({ target: { name: 'rendimentos', value: obj } });
  };
  return (
    <div className="Rendimentos">
      {cols.map((c, i) => (
        <div key={`col-${c.name}`}>
          <div className="tbl-col" style={{ background: (i ? 'rgba(0,122,188,0.6)' : '#007ABC') }}>{c.text}</div>
          <div>
            <Perg label="Rendimento Mensal" name={`${c.name}.mensal`} value={c.value.mensal || 0} cols="1" change={saveValue} type="money" />
          </div>
          <div className="tbl-col">Declaração de IR</div>
          <Perg
            type="opt"
            options={[
              { key: `${c.name}.rendIrComp`, value: 'completa', label: 'Completa' },
              { key: `${c.name}.rendIrSimp`, value: 'simplificada', label: 'Simplificada' }
            ]}
            name={`${c.name}.ir`}
            value={c.value.ir}
            cols="1"
            change={(e) => { saveValue(e, c); }}
          />
          <div className="tbl-col">Regime de contratação</div>
          <Perg
            type="opt"
            options={[
              { key: `${c.name}.RendRegClt`, value: 'clt', label: 'CLT' },
              { key: `${c.name}.RendRegLiberal`, value: 'liberal', label: 'Autônomo/Liberal' },
              { key: `${c.name}.RendRegPublico`, value: 'publico', label: 'Funcionário Público' }
            ]}
            name={`${c.name}.regime`}
            value={c.value.regime}
            cols="1"
            change={saveValue}
          />
        </div>
      ))}
    </div>
  );
};

Rendimentos.propTypes = {
  data: PropTypes.object,
  save: PropTypes.func
};

export default Rendimentos;
