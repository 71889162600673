import PropTypes from 'prop-types';
import Perg from '../Perg';

const Page = ({ data, saveValue }) => (
  <section>
    <div className="formgroup">
      <h2>Informações Adicionais</h2>
      <Perg label="Qual a sua profissão? A que você se dedica atualmente?" name="profis_atual" value={data.profis_atual} cols="1" change={saveValue} />
      <Perg label="Pratica algum esporte? Você tem algum hobby?" name="esporte_hobby" value={data.esporte_hobby} cols="1" change={saveValue} />
      <Perg label="Como está a sua saúde? Toma algum medicamento de forma contínua? Qual?" name="saude_medicamento" value={data.saude_medicamento} cols="1" change={saveValue} />
      <Perg label="Já realizou alguma cirurgia complexa? Qual?" name="realizou_cirurgia" value={data.realizou_cirurgia} cols="1" change={saveValue} />
      <Perg label="Tem ou já teve alguma Doença Crônica ou Grave (acidente vascular, infarto, insuficiência renal, câncer, diabetes, etc.)?" name="doenca_grave" value={data.doenca_grave} cols="1" change={saveValue} />
      <Perg type="opt" label="Você fuma?" name="fuma" value={data.fuma} options={[{ key: 'fumaS', value: 1, label: 'Sim' }, { key: 'fumaN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
      <Perg label="Como está a saúde de seu cônjuge? Ela/ele toma algum medicamento de forma contínua? Qual?" name="saude_conjuge" value={data.saude_conjuge} cols="1" change={saveValue} />
      <Perg type="opt" label="Depois desta análise, suas prioridades continuam sendo as mesmas?" name="analise_priori_mesm" value={data.analise_priori_mesm} options={[{ key: 'analise_priori_mesmS', value: 1, label: 'Sim' }, { key: 'analise_priori_mesmN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
      <Perg type="opt" label="Concorda que você tem uma necessidade de proteção, que não está atendida?" name="necess_protec" value={data.necess_protec} options={[{ key: 'necess_protecS', value: 1, label: 'Sim' }, { key: 'necess_protecN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
      <Perg label="Se eu lhe apresentar uma solução que permita que você tenha um plano de proteção financeira, de acordo com a sua capacidade e necessidade, estaria interessado em conhecê-la e tomar uma decisão a respeito?" name="solucao_interesse" value={data.solucao_interesse} cols="1" change={saveValue} />
      <Perg label="Que valor estaria disposto a investir para proteger 100% das necessidades de sua família que levantamos hoje?" name="valor_proteg_fam" value={data.valor_proteg_fam} cols="1" change={saveValue} />
      <Perg label="Há mais alguém que precise estar presente em nossa próxima conversa para a tomada de decisões (cônjuge ou companheiro(a))? Quem?" name="alguem_prox_conv" value={data.alguem_prox_conv} cols="1" change={saveValue} />
      <Perg label="Caso a solução de proteção esteja adequada e fique dentro do valor que está disposto a investir, existe algum fator que o impediria de concretizá-la?" name="fator_impedir" value={data.fator_impedir} cols="1" change={saveValue} />
      <Perg type="inline" cols="1" change={saveValue}>
        Nossa conversa para apresentação da solução de proteção para sua família será dia
        &#0;
        <input style={{ width: 'calc(135px + 1.4vw)' }} name="apresenta_dia" type="date" value={data.apresenta_dia || ''} onChange={saveValue} />
        &#0;
        às
        &#0;
        <input style={{ width: '90px' }} name="apresenta_hora" type="time" value={data.apresenta_hora || ''} onChange={saveValue} />
        <br />
        Local:
        &#0;
        <input style={{ width: '50%' }} name="apresenta_local" type="text" value={data.apresenta_local || ''} onChange={saveValue} />
      </Perg>
      <Perg label="Observação" type="area" value={data.pg6_obs} name="pg6_obs" change={saveValue} />
    </div>
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
