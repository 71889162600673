import PropTypes from 'prop-types';
import Perg from '../Perg';
import TblGroup from '../TblGroup';
import Rendimentos from '../Rendimentos';
import Cell from '../Cell';

const Page = ({ data, saveValue }) => (
  <section>
    <div className="formgroup">
      <h2>Informação Patrimonial</h2>
      <TblGroup
        data={data}
        save={saveValue}
        name="patrimonio_atual"
        rows={{
          main: (
            <div>
              Qual o valor de seu Património Total hoje?
              <br />
              (considerando imóveis, carros e participações societárias).
              <br />
              Sobre esse valor será feito cálculo de inventário.
            </div>
          )
        }}
        cols={['Património / Valor Atual']}
      />
      <TblGroup
        data={data}
        save={saveValue}
        name="patrimonio"
        rows={{
          main: (
            <div>
              Se você não estivesse mais aqui hoje,
              <br />
              com quanto financeiramente sua família
              <br />
              pode contar de maneira imediata
            </div>
          ),
          sub: [
            { text: 'Recursos Financeiros (dinheiro, poupança etc)', name: 'recursos' },
            { text: 'Veículos', name: 'veiculos' },
            { text: 'Imóveis', name: 'imoveis' },
            { text: 'Previdência', name: 'previdencia' },
          ]
        }}
        cols={['Património / Valor Atual']}
        total="Total"
      />
      <TblGroup
        data={data}
        save={saveValue}
        name="tem_seguro"
        rows={{
          main: <div>Atualmente você tem algum seguro?</div>,
          sub: [
            { text: 'Vida Individual', name: 'vida_individual' },
            { text: 'Empresarial', name: 'empresarial' },
          ]
        }}
        cols={[{ name: 'titular', text: 'Titular' }, { name: 'conjuge', text: 'Cônjuge' }]}
        total="Total:"
      />
      <TblGroup
        data={data}
        save={saveValue}
        name="dividas_atuais"
        rows={{
          main: (
            <div>
              Quais são as suas dívidas atuais?
              (quais os valores que sua família teria que assumir em caso de seu falecimento).
            </div>
          ),
          sub: [
            { text: 'Financiamentos', name: 'financiamentos' },
            { text: 'Prestações diversas', name: 'prestações' },
            { text: 'Empréstimos pessoais', name: 'emprestimos_pessoais' },
            { text: 'Outros', name: 'outros' },
          ]
        }}
        cols={['Património / Valor Atual']}
        total="Total:"
      />
      <Rendimentos data={data.rendimentos} save={saveValue} />
      <Cell
        label="Total"
        style={{ alignSelf: 'flex-start', marginLeft: '1em' }}
        value={data?.rendimentos?.total || 0}
        col
        money
      />
    </div>
    <hr />
    <div className="formgroup">
      <h2>Necessidades Póstumas</h2>
      <TblGroup
        data={data}
        save={saveValue}
        name="valores_fam_falec"
        rows={{
          main: <div>Quais são os valores que sua família necessitará despender com seu falecimento?</div>,
          sub: [
            { text: 'Jazigo/ Cremação RS', name: 'cremacao' },
            { text: 'Despesas Funeral (urna, flores, etc.)', name: 'Funeral' },
            { text: 'Outros', name: 'outros2' },
          ]
        }}
        cols={['Património / Valor Atual']}
        total="Total:"
      />
      <Perg type="opt" label="Sua família terá gastos com inventário para partilha dos bens. Para você é importante garantir que esse valor fique garantido / protegido? " name="import_valor_garan" value={data.import_valor_garan} options={[{ key: 'import_valor_garanS', value: 1, label: 'Sim' }, { key: 'import_valor_garanN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
      <Perg label="Observação" type="area" value={data.pg5_obs} name="pg5_obs" change={saveValue} />
    </div>
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
