import PropTypes from 'prop-types';

const Indice = ({ show, goto, click }) => (
  <div className="indice-el">
    <a className="btn-indice" onClick={click} onKeyPress={click} role="button" tabIndex={0}>Seções</a>
    <div className={`indice${(!show ? ' hide' : '')}`}>
      <ul role="menu">
        <li onClick={() => goto(1)} onKeyPress={() => goto(1)} role="menuitem" tabIndex={0}>Perguntas Iniciais</li>
        <li onClick={() => goto(2)} onKeyPress={() => goto(2)} role="menuitem" tabIndex={0}>Proteção Financeira</li>
        <li onClick={() => goto(3)} onKeyPress={() => goto(3)} role="menuitem" tabIndex={0}>Datas de Nascimento e Educação</li>
        <li onClick={() => goto(4)} onKeyPress={() => goto(4)} role="menuitem" tabIndex={0}>Sonhos e Projetos para o Futuro</li>
        <li onClick={() => goto(5)} onKeyPress={() => goto(5)} role="menuitem" tabIndex={0}>Informação Patrimonial</li>
        <li onClick={() => goto(6)} onKeyPress={() => goto(6)} role="menuitem" tabIndex={0}>Informações Adicionais - Saúde e Riscos</li>
        <li onClick={() => goto(7)} onKeyPress={() => goto(7)} role="menuitem" tabIndex={0}>Necessidades Póstumas</li>
        <li onClick={() => goto(8)} onKeyPress={() => goto(8)} role="menuitem" tabIndex={0}>Obtenção de Recomendações e Agendamento do Fechamento</li>
        <li onClick={() => goto(9)} onKeyPress={() => goto(9)} role="menuitem" tabIndex={0}>Arquivos</li>
      </ul>
    </div>
  </div>
);

Indice.propTypes = {
  show: PropTypes.any.isRequired,
  goto: PropTypes.func.isRequired,
  click: PropTypes.func.isRequired
};

export default Indice;
