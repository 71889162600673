import { useRoutes, Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
// import Account from 'src/pages/Account';
// import CustomerList from 'src/pages/CustomerList';
// import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import Logout from 'src/pages/Logout';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
// import Settings from 'src/pages/Settings';
import Cotacao from 'src/components/cotacao/App';
import Plano from 'src/pages/Plano';

const Routes = ({ online, setToken, getToken }) => useRoutes([
  online ? {
    path: '/',
    element: <DashboardLayout user={online} />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: 'dashboard', element: <ProductList funcs={{ getToken }} /> },
      { path: 'cotacao/new', element: <Cotacao funcs={{ getToken }} /> },
      { path: 'cotacao/:edimp/:id/', element: <Cotacao funcs={{ getToken }} /> },
      { path: 'meu-plano', element: <Plano funcs={{ getToken, setToken }} /> },
      { path: 'logout', element: <Logout setToken={setToken} /> },
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  } : {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login setToken={setToken} /> },
      { path: 'cadastro', element: <Plano cad funcs={{ getToken, setToken }} /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/login" /> },
    ]
  }
]);

export default Routes;
