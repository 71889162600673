import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import Routes from 'src/Routes';
import { useState } from 'react';
import './theme/header.css';

const App = () => {
  const getToken = (u) => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return u ? userToken?.user : userToken?.token;
  };
  const [user, setUser] = useState(getToken(true));
  const setToken = (userToken) => {
    if (!userToken) sessionStorage.clear();
    else sessionStorage.setItem('token', JSON.stringify(userToken));
    setUser(userToken);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes online={user} setToken={setToken} getToken={getToken} />
    </ThemeProvider>
  );
};

export default App;
