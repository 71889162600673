import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Print from '@material-ui/icons/Print';
import Edit from '@material-ui/icons/Edit';
import Person from '@material-ui/icons/Person';

const ProductCard = ({ product, user, ...rest }) => {
  const navigate = useNavigate();
  const maskPhone = (phone) => {
    if (!phone) return undefined;
    let tel = phone.replace(/\D/g, '');
    tel = tel.replace(/^(\d)/, '($1');
    tel = tel.replace(/(.{3})(\d)/, '$1) $2');
    if (tel.length === 10) {
      tel = tel.replace(/(.{1})$/, '-$1');
    } else if (tel.length === 11) {
      tel = tel.replace(/(.{2})$/, '-$1');
    } else if (tel.length === 12) {
      tel = tel.replace(/(.{3})$/, '-$1');
    } else if (tel.length === 13) {
      tel = tel.replace(/(.{4})$/, '-$1');
    } else if (tel.length === 14) {
      tel = tel.replace(/(.{8})$/, ' $1').replace(/(.{4})$/, '-$1');
    } else if (tel.length > 14) {
      tel = tel.replace(/(.{9})$/, ' $1').replace(/(.{5})$/, '-$1').substr(0, 16);
    }
    return tel;
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      {...rest}
    >
      <CardContent className="link" onClick={() => { if (user?.role_id === 9 || user?.id === product?.user_id) navigate(`/cotacao/edit/${product?.id}`); }}>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.nome || '-'}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {maskPhone(product.telefone) || '-'}
        </Typography>
      </CardContent>
      {user?.role_id === 9 ? (
        <>
          <Divider />
          <Box sx={{ p: 2, pt: 1, pb: 1 }}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid
                item
                sx={{
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <Person color="action" />
                <Typography
                  color="textSecondary"
                  display="inline"
                  sx={{ pl: 1 }}
                  variant="body2"
                >
                  {product.nome_completo}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : ''}
      <Divider />
      <Box sx={{ p: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            {(user?.role_id === 9 || user?.id === product?.user_id) ? (
              <Button component={Link} to={`/cotacao/edit/${product?.id}`}>
                <Edit color="action" />
                <Typography
                  color="textSecondary"
                  display="inline"
                  sx={{ pl: 1 }}
                  variant="body2"
                >
                  Editar
                </Typography>
              </Button>
            ) : (
              <>
                <Person color="action" />
                <Typography
                  color="textSecondary"
                  display="inline"
                  sx={{ pl: 1 }}
                  variant="body2"
                >
                  {product.nome_completo}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <Button component={Link} to={`/cotacao/print/${product?.id}`}>
              <Print color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                Imprimir
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ p: 2, pt: 1, pb: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}
          >
            <AccessTimeIcon color="action" />
            <Typography
              color="textSecondary"
              display="inline"
              sx={{ pl: 1 }}
              variant="body2"
            >
              Atualizado&nbsp;
              {moment(product.updated_at).fromNow()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default ProductCard;
