import PropTypes from 'prop-types';
import Perg from '../Perg';

const Page = ({ data, saveValue }) => (
  <section>
    <div className="formgroup">
      <Perg label="Nome:" name="nome" value={data.nome} cols="2" change={saveValue} />
      <Perg label="Telefone:" name="telefone" value={data.telefone} mask="phone" cols="2" change={saveValue} />
      <Perg type="area" label="Quais são os objetivos financeiros importantes para sua família?" name="obj_fin_fam" value={data.obj_fin_fam} cols="1" change={saveValue} />
    </div>
    <hr />
    <div className="formgroup">
      <h2>Padrão de Vida e Imprevistos</h2>
      <Perg label="Que percentual da sua renda é destinado à manutenção da família?" name="fam_perc_manut" value={data.fam_perc_manut} cols="1" change={saveValue} />
      <Perg label="Você está tranquilo com a situação atual de proteção financeira da sua familia?" name="fam_sit_fin" value={data.fam_sit_fin} cols="1" change={saveValue} />
      <Perg label="Você tem algum negócio próprio? Tem sócios? São seus familiares?" name="fam_socios" value={data.fam_socios} cols="1" change={saveValue} />
      <Perg label="Você gostaria de deixar para sua familia um legado sólido —sem dividas, um negócio sustentável, sem gastos com sucessão / inventário?" name="fam_legado" value={data.fam_legado} cols="1" change={saveValue} />
    </div>
    <hr />
    <div className="formgroup">
      <h2>Educação dos Filhos</h2>
      <Perg label="Conte-me: quais são os seus planos para a educação de seus filhos?" name="edu_filhos" value={data.edu_filhos} cols="1" change={saveValue} />
      <Perg label="Eles estudam no ensino público ou particular?" name="publico_particular" value={data.publico_particular} cols="1" change={saveValue} />
      <Perg label="Você acha importante garantir uma boa educação aos seus filhos? Gostaria de garantir que eles estudassem até a faculdade?" name="importa_edu_facul" value={data.importa_edu_facul} cols="1" change={saveValue} />
      <Perg label="Você tem uma ideia do montante total que irá gastar com essa educação que quer dar aos seus filhos? Qual é o valor?" name="total_edu_valor" value={data.total_edu_valor} cols="1" change={saveValue} />
      <Perg label="De que forma você está garantindo para que isto ocorra?" name="forma_garantindo" value={data.forma_garantindo} cols="1" change={saveValue} />
    </div>
    <hr />
    <div className="formgroup">
      <h2>Aposentadoria</h2>
      <Perg label="Seus pais ou avós estão aposentados? Como eles estão financeiramente? Você gosta da forma como eles estão vivendo?" name="pais_avos_aposent" value={data.pais_avos_aposent} cols="1" change={saveValue} />
      <Perg label="Como você se vê na sua aposentadoria?" name="ve_aposenta" value={data.ve_aposenta} cols="1" change={saveValue} />
      <Perg label="Quem se responsabilizaria financeiramente por você e por seu cônjuge na terceira idade, caso não tenham recursos suficientes?" name="respon_terceira_idade" value={data.respon_terceira_idade} cols="1" change={saveValue} />
      <Perg label="Você gostaria de se manter independente financeiramente nesta fase da vida?" name="manter_independente" value={data.manter_independente} cols="1" change={saveValue} />
      <Perg label="O que você esta fazendo para garantir isto?" name="faz_garantir" value={data.faz_garantir} cols="1" change={saveValue} />
    </div>
    <hr />
    <div className="formgroup">
      <Perg label="Desses pontos que falamos até o momento, qual é o mais importante para você?" name="pontos_importante" value={data.pontos_importante} cols="1" change={saveValue} />
      <Perg label="Quanto desse projeto já está garantido (em %)?" name="projeto_garantido" value={data.projeto_garantido} cols="1" change={saveValue} />
      <Perg label="Para você é importante garantir que isso seja proporcionado / realizado?" name="importa_proporcionado" value={data.importa_proporcionado} cols="1" change={saveValue} />
      <Perg label="Em uma escala de 0 a 10, qual a nota de importância que você daria a esse projeto?" name="importa_projeto" value={data.importa_projeto} type="number" cols="1" change={saveValue} />
      <Perg label="Como está dividida a responsabilidade financeira para realização desse projeto?" name="dividida_responsab" value={data.dividida_responsab} cols="1" change={saveValue} />
    </div>
    <div className="formgroup">
      <Perg label="Observação" type="area" value={data.pg1_obs} name="pg1_obs" change={saveValue} />
    </div>
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
