import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './App.css';
import Naveg from './Naveg';
import Page1 from './Pages/Page1';
import Page2 from './Pages/Page2';
import Page3 from './Pages/Page3';
import Page4 from './Pages/Page4';
import Page5 from './Pages/Page5';
import Page6 from './Pages/Page6';
import Page7 from './Pages/Page7';
import Page8 from './Pages/Page8';
import FileUpload from './FileUpload';
import Indice from './Indice';

function App({ funcs }) {
  const navigate = useNavigate();
  const { id, edimp } = useParams();
  const [page, setPage] = useState(1);
  const [print, setPrint] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const dataRef = useRef();
  const [files, setFiles] = useState([]);
  const toggleInd = () => { setShow(!show); };
  const saveValue = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const scrollTop = () => {
    document.querySelector('.App.cotador').parentElement.scrollTo(0, 0);
  };
  const save = async () => {
    const token = funcs.getToken();
    fetch(`${process.env.REACT_APP_API}/adn/save`, {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      body: JSON.stringify({ ...dataRef.current })
    }).then((response) => response.json()).then((json) => {
      if (json.status === 'success' && data) {
        setData({ ...data, id: json.adn.id });
        // console.log(data);
      } else {
        console.log('erro', json);
      }
    }).catch((error) => {
      console.log(`Erro de conexão: ${error.message}`);
    });
  };
  const nextPage = () => {
    setPage(page + 1);
    save();
    setShow(0);
    scrollTop();
  };
  const lastPage = () => {
    setPage(page - 1);
    save();
    setShow(0);
    scrollTop();
  };
  const gotoPage = (pg) => {
    setPage(pg);
    save();
    setShow(0);
    scrollTop();
  };

  const imprimir = () => {
    setPrint(true);
    setTimeout((elem) => {
      const content = document.getElementById(elem);
      const iframe = document.createElement('iframe');
      iframe.setAttribute('width', '1080px');
      document.body.appendChild(iframe);
      const pri = iframe.contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      const styles = document.querySelectorAll('style');
      for (let i = 0; i < styles.length; i++) {
        if (styles[i].innerHTML.indexOf('.App') > -1) {
          const clone = styles[i].cloneNode();
          clone.innerHTML = styles[i].innerHTML;
          pri.document.head.appendChild(clone);
        }
      }
      pri.focus();
      pri.print();
      setPrint(false);
      setTimeout(() => {
        document.body.removeChild(iframe);
        if (edimp === 'print') navigate('/dashboard', { replace: true });
      }, 1000);
    }, 1000, 'adn-print');
  };

  useEffect(() => {
    scrollTop();
    if (id) {
      const token = funcs.getToken();
      fetch(`${process.env.REACT_APP_API}/adn/${id}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        headers: { 'Content-Type': 'application/json', Authorization: token }
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'success') {
          console.log(json);
          const dados = JSON.parse(json.adn.dados);
          setData({ id, ...dados });
          setFiles(json.files);
          if (edimp !== 'edit') {
            document.querySelectorAll('.App.cotacao input, .App.cotacao textarea, .App.cotacao select').forEach((el) => {
              const elem = el;
              elem.disabled = true;
            });
          }
          if (edimp === 'print') {
            imprimir();
          }
        } else {
          console.log('erro', json);
        }
      }).catch((error) => {
        console.log(`Erro de conexão: ${error.message}`);
      });
    } else {
      setData({});
      setPage(1);
    }
  }, [id, edimp]);

  React.useEffect(() => { dataRef.current = data; }, [data]);

  useEffect(() => () => save(), []);

  const sendFile = (evt) => {
    if (!evt.target.files[0]) return;
    const token = funcs.getToken();
    const form = new FormData();
    form.append('file', evt.target.files[0]);
    fetch(`${process.env.REACT_APP_API}/adn/${id}/file`, {
      method: 'POST',
      headers: {
        Authorization: token
        // 'Content-Type': 'multipart/form-data'
      },
      body: form
    }).then(
      (response) => response.json()
    ).then(
      (json) => {
        console.log(json);
        if (json.status === 'success') setFiles([...files, json.filename]);
      }
    ).catch(
      (error) => console.log(error)
    );
  };

  const download = (file) => {
    const token = funcs.getToken();
    fetch(`${process.env.REACT_APP_API}/adn/${id}/file/${file}`, {
      method: 'GET',
      headers: {
        Authorization: token
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          file.split(file.indexOf('.')),
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const json = JSON.parse(e.target.result);
      setData(json || {});
      // console.log(json);
      if (json) setPage(9);
    };
    reader.readAsText(fileUploaded);
  };
  const makePage = () => {
    switch (page) {
      case 0:
        return (
          <>
            <button type="button" className="btn-enter" onClick={nextPage}>ENTRAR</button>
            <button type="button" className="btn-load" onClick={handleClick}>CARREGAR</button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: 'none' }}
              accept="application/json"
            />
          </>
        );
      case 1:
        return <Page1 data={data} saveValue={saveValue} />;
      case 2:
        return <Page2 data={data} saveValue={saveValue} />;
      case 3:
        return <Page3 data={data} saveValue={saveValue} />;
      case 4:
        return <Page4 data={data} saveValue={saveValue} />;
      case 5:
        return <Page5 data={data} saveValue={saveValue} />;
      case 6:
        return <Page6 data={data} saveValue={saveValue} />;
      case 7:
        return <Page7 data={data} saveValue={saveValue} />;
      case 8:
        return <Page8 data={data} saveValue={saveValue} />;
      case 9:
        return (
          <div>
            <FileUpload files={files} sendFile={sendFile} download={download} />
            <div className="Naveg" style={{ margin: '5em auto 0', justifyContent: 'space-between' }}>
              <button type="button" className="btn-last" onClick={lastPage}>VOLTAR</button>
              <Indice show={show} goto={gotoPage} click={toggleInd} />
              <button type="button" className="btn-next" onClick={imprimir}>IMPRIMIR</button>
            </div>
          </div>
        );
      default:
        break;
    }
    return null;
  };
  if (print) {
    return (
      <div id="adn-print" className="App page1 print">
        <section className="title page1 print">
          ADN - Análise e Despertar de Necessidades
        </section>
        <Page1 data={data} saveValue={() => {}} />
        <Page2 data={data} saveValue={() => {}} />
        <Page3 data={data} saveValue={() => {}} />
        <Page4 data={data} saveValue={() => {}} />
        <Page5 data={data} saveValue={() => {}} />
        <Page6 data={data} saveValue={() => {}} />
        <Page7 data={data} saveValue={() => {}} />
        <Page8 data={data} saveValue={() => {}} />
      </div>
    );
  }
  return (
    <div className={`App cotador page${page}`}>
      <section className={`title page${page}`}>
        ADN - Análise e Despertar
        <br />
        de Necessidades
      </section>
      {page > 1 && page < 9 ? (
        <div className="Etapa">
          <span>
            Etapa
            &#0;
            {page}
            &#0;
            de 8
          </span>
          <hr />
        </div>
      ) : ''}
      {makePage()}
      {page > 0 && page < 9 ? (
        <Naveg page={page} next={nextPage} last={lastPage} goto={gotoPage} show={show} toggleInd={toggleInd} />
      ) : ''}
    </div>
  );
}

App.propTypes = {
  funcs: PropTypes.object
};

export default App;
