import PropTypes from 'prop-types';
import Perg from '../Perg';
import Line from '../LineTable';
import TblGroup from '../TblGroup';
import Cell from '../Cell';

const Page = ({ data, saveValue }) => (
  <section>
    <div className="formgroup">
      <h2>Necessidades Póstumas</h2>
      <TblGroup
        data={data}
        save={saveValue}
        name="info_patrimonio"
        rows={{
          main: (
            <div>
              Gastos imediatos em caso de falecimento.
              <br />
              Quem cobriria estes gastos?
            </div>
          ),
          sub: [
            { text: 'Reorganização Póstuma (3 a 6 vezes o salário)', name: 'reorganizacao' },
            { text: 'Necessidades Póstumas', name: 'necessidades' },
            { text: 'Gastos de Inventário (11% do valor dos imóveis- ITCMD, Advogado e registros)', name: 'inventario' },
          ]
        }}
        total="Total Despesas Vitalícias"
      />
    </div>
    <hr />
    <div className="formgroup">
      <h2>Capital ideal para cobrir as necessidades do dia a dia e estudos</h2>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Cell label="Valor mensal necessário" value={data.estudos_valor_mensal} name="estudos_valor_mensal" save={saveValue} col money />
        <span style={{ minWidth: '3em' }}>x 12 =</span>
        <Cell label="Valor anual necessário" value={data.estudos_valor_mensal * 12} name="estudos_valor_anual" col money />
        <span style={{ minWidth: '1em' }}> = </span>
        <div className="lt-val" style={{ minWidth: '6em' }}>
          <Perg type="money" value={data.estudos_valor_mensal * 12} />
        </div>
      </div>
      <Line
        text={['Capital Necessário Proteção Dia a Dia este Capital garantirá seus planos objetivos e preocupações', 'Trazer valor I']}
        value={data.line_diadia}
        name="line_diadia"
        save={saveValue}
      />
      <Line
        text={['Total de Gastos com Educação Valores necessários para garantir estudos', 'Trazer valor A menos a reserva']}
        value={data.line_educ}
        name="line_educ"
        save={saveValue}
      />
      <Line
        text={['Capital Necessário Proteção Sonhos Valores necessários para garantir sonhos', 'Trazer valor Sonhos']}
        value={data.line_sonhos}
        name="line_sonhos"
        save={saveValue}
      />
      <Line
        text={['Capital Necessário do Passivo Valores necessários para garantir passivo', 'Trazer valor E']}
        value={data.line_passivo}
        name="line_passivo"
        save={saveValue}
      />
      <Line
        text={['Trazer Ativos Realizáveis. Bens que a familia pode utilizar para atender suas necessidades em caso de falecimentos', 'Trazer valor C']}
        value={data.line_ativo}
        name="line_ativo"
        save={saveValue}
      />
      <Line
        text={['Ingressos Imediatos ao Falecimento Seguros de vida', 'Trazer valor D']}
        value={data.line_falecimento}
        name="line_falecimento"
        save={saveValue}
      />
      <Line
        text={['Capital Necessário Liquido Dia a dia + estudos sonhos + passivo ']}
        value={data.line_diadia + data.line_educ + data.line_sonhos + data.line_passivo}
      />
    </div>
    <hr />
    <div className="formgroup" style={{ alignItems: 'center' }}>
      <h2>Necessidades Póstumas</h2>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Cell label="Valor mensal necessário" value={data.postumas_mensal} name="postumas_mensal" save={saveValue} col money />
        <span style={{ minWidth: '0.8em' }}> - </span>
        <Cell label="Valor INSS" value={data.postumas_inss} name="postumas_inss" save={saveValue} col money />
        <span style={{ minWidth: '0.8em' }}> = </span>
        <Cell label="Valor renda adicional " value={data.postumas_mensal - data.postumas_inss} col money />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Cell label="Idade de aposentadoria" value={data.idade_aposentadoria} name="idade_aposentadoria" save={saveValue} col />
      </div>
      <Perg label="Observação" type="area" value={data.pg7_obs} name="pg7_obs" change={saveValue} />
    </div>
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
