import PropTypes from 'prop-types';
import Perg from './Perg';

const TblGroup = ({
  data, save, name, rows, cols, total, className
}) => {
  const saveSub = (e, r, c) => {
    const val = e.target.value;
    const obj = { ...data[name] };
    if (typeof c === 'undefined' || !c) {
      obj[r] = val;
    } else {
      obj[r] = obj[r] || {};
      obj[r][c] = val;
    }
    save({ target: { name, value: obj } });
  };
  const sumTot = (c) => {
    if (typeof c === 'undefined' || !c) {
      return Object.keys(data[name] || {}).reduce((a, v) => (a + data[name][v]), 0);
    }
    return Object.keys(data[name] || {}).reduce((a, v) => (a + data[name][v][c]), 0);
  };
  if (!rows.sub && cols.length === 1) {
    return (
      <div className={`TblGroup${(className || '')}`}>
        <div className="tbl-main" style={{ gridRowEnd: 'span 2', gridColumnEnd: 'span 4' }}>{rows.main}</div>
        <div className="tbl-head">{cols[0]}</div>
        <div className="tbl-val"><Perg type="money" name={name} change={save} value={data[name]} /></div>
      </div>
    );
  }
  const head = cols?.length > 0 ? (
    [
      <div key="head" style={{ gridColumnEnd: `span ${(5 - cols.length)}` }} />,
      cols.map((c) => <div key={`h${name + c.name}`} className="tbl-head">{c.text || c}</div>)
    ]
  ) : '';
  const end = total ? (
    [
      <div key="space" style={{ gridColumnEnd: (`span ${(4 - (cols?.length || 1))}`) }} />,
      <div key="total" className="tbl-tot"><div>{total}</div></div>,
      cols ? cols.map((c) => <div key={`tot${name + c.name}`} className="tbl-val"><Perg type="money" change={() => {}} value={sumTot(c.name)} /></div>) : <div key={`tot${name}`} className="tbl-val"><Perg type="money" change={() => {}} value={sumTot()} /></div>
    ]
  ) : '';
  const mid = [
    <div key="main" className="tbl-main" style={{ gridRowEnd: (`span ${(rows?.sub?.length)}`) }}>{rows.main}</div>,
    rows.sub.map((r) => [
      <div key={`r${name + r.name}`} className="tbl-sub" style={{ gridColumnEnd: `span ${(3 - (cols?.length || 1))}` }}>{r.text}</div>,
      cols ? cols.map((c) => (
        <div key={`c${name + r.name + c.name}`} className="tbl-val">
          <Perg
            type="money"
            change={(e) => { saveSub(e, r.name, c.name); }}
            value={c.name ? (data?.[name]?.[r.name]?.[c.name]) : (data?.[name]?.[r.name])}
          />
        </div>
      )) : <div key={`c${name + r.name}`} className="tbl-val"><Perg type="money" change={(e) => { saveSub(e, r.name); }} value={data?.[name]?.[r.name]} /></div>
    ])
  ];
  return (
    <div className={`TblGroup ${(className || '')}`}>
      {head}
      {mid}
      {end}
    </div>
  );
};

TblGroup.propTypes = {
  data: PropTypes.object,
  save: PropTypes.func,
  name: PropTypes.string,
  rows: PropTypes.any,
  cols: PropTypes.any,
  total: PropTypes.any,
  className: PropTypes.string
};

export default TblGroup;
