import PropTypes from 'prop-types';
import Perg from './Perg';

const EducTable = ({ data, index, save }) => {
  const rows = [
    {
      name: 'maternal', label: 'Maternal (4 anos)', id: '0_maternal'
    },
    {
      name: 'fundamental', label: 'Fundamental (9 anos)', id: '1_fundamental'
    },
    {
      name: 'medio', label: 'Médio (3 anos)', id: '2_medio'
    },
    {
      name: 'superior', label: 'Superior (4 a 5 anos)', id: '3_superior'
    },
    {
      name: 'pos', label: 'Pós-Graduação(2 anos)', id: '4_pos'
    },
    {
      name: 'outros', label: 'Intercâmbio / Outros', id: '5_outros'
    },
  ];
  const cols = [
    {
      name: 'custo', label: 'Custo mensal', type: 'money', id: '0_custo'
    },
    {
      name: 'anos', label: 'Anos restantes', type: 'number', id: '1_anos'
    },
    {
      name: 'total', label: 'Total = Custo Mensal x 12 x  Anos restantes', type: 'money', id: '2_total'
    },
  ];
  const change = (e, r, c) => {
    if (c === 'total') return;
    let val = e.target.value;
    if (c === 'anos') val = parseFloat(val) || 0;
    const obj = { ...data?.[index] };

    obj[r] = obj[r] || {};
    obj[r][c] = val;

    obj[r].total = obj[r].custo * 12 * obj[r].anos || 0;
    obj.total = {
      anos: Object.keys(obj || {}).reduce((a, v) => {
        if (v === 'total') return a;
        return a + (obj[v]?.anos || 0);
      }, 0),
      custo: Object.keys(obj || {}).reduce((a, v) => {
        if (v === 'total') return a;
        return a + (obj[v]?.total || 0);
      }, 0)
    };

    save(index, obj);
  };
  const calcTotCusto = Object.keys(data?.[index] || {}).reduce((a, v) => {
    if (v === 'total') return a;
    return a + (data[index][v]?.total || 0);
  }, 0);

  const calcTotAnos = Object.keys(data?.[index] || {}).reduce((a, v) => {
    if (v === 'total') return a;
    return a + (data[index][v]?.anos || 0);
  }, 0);

  return (
    <div className="EducTable">
      <div className="blank" />
      <div className="iterator">
        Filho
        {index + 1}
      </div>
      <div className="blank" />
      {cols.map((c, i) => [
        <div key={`tc${c.id}`} className={`tit col${i}`}>{c.label}</div>
      ])}
      {rows.map((r) => [
        <div key={`tr${r.name}`} className="row">{r.label}</div>,
        cols.map((c, i) => [
          <div key={`rc${c.id}${r.id}`} className={`val col${i}`}>
            <Perg
              type={c.type}
              value={(data?.[index]?.[r.name]?.[c.name]) || ''}
              change={(e) => { change(e, r.name, c.name); }}
            />
          </div>
        ])
      ])}
      <div className="tot">Total</div>
      <div className="col1 val">
        <Perg
          type="number"
          value={calcTotAnos}
          change={() => {}}
        />
      </div>
      <div className="col2 val">
        <Perg
          type="money"
          value={calcTotCusto}
          change={() => {}}
        />
      </div>
    </div>
  );
};

EducTable.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  save: PropTypes.func
};

export default EducTable;
