import PropTypes from 'prop-types';
import Perg from '../Perg';

const Page = ({ data, saveValue }) => (
  <section className="formgroup">
    <h2>Proteção Financeira:</h2>
    <Perg label="Qual a opinião que você tem de seguro de vida?" name="opiniao_seguro" value={data.opiniao_seguro} cols="1" change={saveValue} />
    <Perg label="Por que acha isso?" name="pq_acha" value={data.pq_acha} cols="1" change={saveValue} />
    <Perg type="opt" label="Possui seguro de vida?" name="seguro" value={data.seguro} options={[{ key: 'seguroS', value: 1, label: 'Sim' }, { key: 'seguroN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
    <Perg label="Se sim, como definiu o valor de cobertura?" name="def_valor_cober" value={data.def_valor_cober} cols="1" change={saveValue} />
    <Perg label="Por que fez esse seguro de vida?" name="pq_fez" value={data.pq_fez} cols="1" change={saveValue} />
    <Perg label="Quem paga pelo seguro?" name="quem_paga" value={data.quem_paga} cols="2" change={saveValue} />
    <Perg label="Onde fez?" name="onde_fez" value={data.onde_fez} cols="2" change={saveValue} />
    <Perg type="opt" label="Você fez entrevista médica ou exames quando contratou esse seguro?" name="entrevista" value={data.entrevista} options={[{ key: 'entrevistaS', value: 1, label: 'Sim' }, { key: 'entrevistaN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
    <Perg label="De 0 a 10, qual a nota de importância que você dá a esse seguro?" name="importa_seguro" value={data.importa_seguro} type="number" cols="1" change={saveValue} />
    <Perg type="opt" label="Está contente com o seguro de vida que possui?" name="contente" value={data.contente} options={[{ key: 'contenteS', value: 1, label: 'Sim' }, { key: 'contenteN', value: 0, label: 'Não' }]} cols="1" change={saveValue} />
    <Perg label="Observação" type="area" value={data.pg2_obs} name="pg2_obs" change={saveValue} />
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
