import PropTypes from 'prop-types';
import Perg from '../Perg';

const Page = ({ data, saveValue }) => (
  <section>
    <div className="formgroup">
      <Perg label="Obtenção de Recomendações" name="recomendacoes" type="area" value={data.recomendacoes} cols="1" change={saveValue} />
      <Perg type="inline" cols="1" change={saveValue}>
        &#0;
        Visita para apresentação dia
        &#0;
        <input type="date" name="visita_apresenta_dia" value={data.visita_apresenta_dia || ''} onChange={saveValue} />
        &#0;
        às
        &#0;
        <input type="time" name="visita_apresenta_hora" value={data.visita_apresenta_hora || ''} onChange={saveValue} />
      </Perg>
      <Perg label="Endereço" name="endereco" value={data.endereco} cols="1" change={saveValue} />
      <Perg label="Consultor" name="consultor" value={data.consultor} cols="2" change={saveValue} />
      <Perg label="Telefone" name="telefone_consult" mask="phone" value={data.telefone_consult} cols="2" change={saveValue} />
      <Perg label="Observação" type="area" value={data.pg8_obs} name="pg8_obs" change={saveValue} />
    </div>
  </section>
);

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
