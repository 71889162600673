import PropTypes from 'prop-types';
import Perg from '../Perg';
import TblGroup from '../TblGroup';
import Cell from '../Cell';

const Page = ({ data, saveValue }) => {
  const saveGastos = (evt) => {
    const e = { ...evt };
    e.target.value.total = Object.keys(e.target.value || {}).reduce((a, v) => {
      if (v === 'total') return a;
      return a + (e.target.value[v] || 0);
    }, 0);
    saveValue(e);
  };
  return (
    <section>
      <div className="formgroup">
        <h2>Sonhos e Projetos para o futuro</h2>
        <Perg label="Você tem um sonho para o futuro (médio / longo prazo) que necessite de recursos financeiros? Qual?" name="sonho_futuro" value={data.sonho_futuro} cols="1" change={saveValue} />
        <Perg label="De qual valor necessitaria para que ele se realizasse?" name="valor_necessario" value={data.valor_necessario} cols="1" change={saveValue} />
        <Perg label="E sua aposentadoria? Com que idade você pensa em se aposentar?" name="aposenta_idade" type="number" value={data.aposenta_idade} cols="1" change={saveValue} />
        <Perg label="Qual o valor de renda mensal que você deseja ter na aposentadoria?" name="renda_mes_aposenta" value={data.renda_mes_aposenta} cols="1" change={saveValue} />
      </div>
      <hr />
      <div className="formgroup">
        <h2>Sonhos e Projetos para o futuro</h2>
        <TblGroup
          data={data}
          save={saveGastos}
          name="gastos_familiares"
          rows={{
            main: ['Qual é seu gasto', <br key="br" />, 'familiar mensal?'],
            sub: [
              { name: 'fixos', text: 'Gastos Fixos (Alimentação, Aluguel, Condomínio, Água, Luz, Telefone, Gás, Gasolina, Vestuário, Plano de saúde, impostos, etc.) ' },
              { name: 'lazer', text: 'Lazer (passeios, férias. festas, restaurantes, etc.) ' },
              { name: 'seguros', text: 'Seguros e Previdência (pagamentos mensais) ' },
              { name: 'outros', text: 'Outros (ajuda aos pais, diarista, etc.) ' },
            ]
          }}
          className="blue"
        />
        <Cell
          label="Total de Gastos Mensais Familiares "
          value={data?.gastos_familiares?.total || 0}
          cols={2}
          money
        />
        <Cell
          label="Gastos Familiares com a sua falta (- 20%)"
          value={(data?.gastos_familiares?.total || 0) * 0.8}
          cols={2}
          money
        />
        <Perg label="É importante para você que sua família consiga manter esse padrão, mesmo que você não esteja mais aqui para fazê-lo?" name="import_fam_padrao" value={data.import_fam_padrao} cols="1" change={saveValue} />
        <Perg label="Em uma escala de 0 a 10, qual a nota de importância que você daria para a garantia da manutenção do dia a dia de sua família?" name="import_garan_fam" value={data.import_garan_fam} type="number" cols="1" change={saveValue} />
        <Perg label="Se sua família não pudesse mais contar com sua renda devido a uma ausência prematura, como ela resolveria esse problema financeiro?" name="ausen_probl_finan" value={data.ausen_probl_finan} cols="1" change={saveValue} />
        <Perg label="Por quantos anos sua família ainda precisará de seu apoio financeiro - considerando seus filhos mais novos?" name="anos_fam_apoio" value={data.anos_fam_apoio} cols="1" change={saveValue} />
        <Perg label="Observação" type="area" value={data.pg4_obs} name="pg4_obs" change={saveValue} />
      </div>
    </section>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired
};

export default Page;
