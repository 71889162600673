import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import AccountProfile from 'src/components/plano/AccountProfile';
import AccountProfileDetails from 'src/components/plano/AccountProfileDetails';

const Plano = ({ cad, funcs }) => {
  const user = funcs.getToken(true);
  const token = funcs.getToken();
  console.log(user, token);

  return (
    <>
      <Helmet>
        <title>Account | Material Kit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            { !cad ? (
              <Grid
                item
                lg={3}
                md={4}
                xs={12}
              >
                <AccountProfile user={user} />
              </Grid>
            ) : null }
            <Grid
              item
              lg={cad ? 12 : 9}
              md={cad ? 12 : 8}
              xs={12}
            >
              <AccountProfileDetails user={user} token={token} funcs={funcs} cad={cad} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

Plano.propTypes = {
  cad: PropTypes.bool,
  funcs: PropTypes.object.isRequired
};

export default Plano;
