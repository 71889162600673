import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const Logout = ({ setToken }) => {
  useEffect(() => {
    setToken();
  });
  return (
    <Navigate to="/" />
  );
};

Logout.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Logout;
