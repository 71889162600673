import PropTypes from 'prop-types';
import { useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import Publish from '@material-ui/icons/Publish';
import GetApp from '@material-ui/icons/GetApp';

const FileUpload = ({ files, sendFile, download }) => {
  const renderFiles = files.map((file) => (
    <Box sx={{ p: 2, pb: 1 }} key={file.slice(0, file.indexOf('.'))}>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          item
          sx={{
            alignItems: 'center'
          }}
        >
          <Button onClick={() => download(file)}>
            <GetApp color="action" />
            <Typography
              color="textSecondary"
              display="inline"
              sx={{ pl: 1 }}
              variant="body2"
            >
              {file.slice(file.indexOf('.') + 1)}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  ));
  const inputRef = useRef();
  return (
    <Card
      className="FormUpload"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        margin: '2.5em auto'
      }}
    >
      <Box sx={{ p: 2, pb: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'center' }}
        >
          <Grid
            item
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h4"
            >
              Arquivos
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ m: 0 }} />
      {files.length > 0 ? (
        <>
          <CardContent>
            {renderFiles}
          </CardContent>
          <Divider />
        </>
      ) : '' }
      <Box sx={{ p: 1 }}>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'center' }}
        >
          <Grid
            item
            sx={{
              alignItems: 'center'
            }}
          >
            <Button onClick={() => { inputRef.current.click(); }}>
              <Publish color="action" />
              <Typography
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                Enviar
              </Typography>
            </Button>
            <input type="file" hidden ref={inputRef} onChange={sendFile} />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

FileUpload.propTypes = {
  files: PropTypes.array,
  sendFile: PropTypes.func,
  download: PropTypes.func
};

export default FileUpload;
