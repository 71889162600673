import PropTypes from 'prop-types';
import Perg from './Perg';

const Line = ({
  text, value, save, name
}) => (
  <div className="LineTable">
    {text.map((t, k) => <div key={`line${name + k}`} className="lt-color">{t}</div>)}
    <div className="lt-val"><Perg type="money" name={name} change={save || (() => {})} value={value} /></div>
  </div>
);

Line.propTypes = {
  text: PropTypes.any,
  value: PropTypes.number,
  save: PropTypes.func,
  name: PropTypes.string
};

export default Line;
