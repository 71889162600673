import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';

const SpliceStr = (str, start, delCount, newSubStr) => (str.slice(0, start) + newSubStr + str.slice(start + Math.abs(delCount)));

const AccountProfileDetails = ({
  user, token, funcs, cad, ...props
}) => {
  const [values, setValues] = useState(user);
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const validate = (v) => {
    const vals = v || values;
    const erros = {};
    if (!v && (!vals?.document || vals?.document?.length === 0)) {
      erros.document = 'CPF / CNPJ é obrigatório!';
    } else if (vals?.document?.length < 11 || (vals?.document?.length > 11 && vals?.document?.length < 14)) {
      erros.document = 'CPF / CNPJ incompleto!';
    }
    if (cad && !v && (!vals?.senha || vals?.senha?.length === 0)) {
      erros.senha = 'Senha é obrigatória!';
    } else if (vals?.senha?.length > 0 && vals?.senha?.length < 6) {
      erros.senha = 'Senha precisa pelo menos 6 caracteres.';
    }
    if (vals?.senha && vals?.senha !== vals?.senha2) {
      erros.senha2 = 'Senhas diferentes!';
    }
    if (!v && (!vals?.nome_completo || vals?.nome_completo?.length === 0)) {
      erros.nome_completo = 'Nome é obrigatório!';
    }
    if (!v && (!vals?.email || vals?.email?.length === 0)) {
      erros.email = 'E-mail é obrigatório!';
    } else if (vals?.email?.length > 0 && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(vals?.email)) {
      erros.email = 'E-mail inválido!';
    }
    setErrors(erros);
    return erros;
  };

  const handleChange = (event) => {
    let val = event.target.value;
    if (event.target.name === 'document') val = val.replace(/[^0-9]+/g, '');
    setValues({ ...values, [event.target.name]: val });
    validate({ ...values, [event.target.name]: val });
  };

  const maskCpfCnpj = (val) => {
    let value = (val || '').replace(/[^0-9]+/g, '');
    if (value.length > 15) value = value.substring(0, 15);
    if (value.length > 11) {
      if (value.length > 12) value = SpliceStr(value, value.length - 12, 0, '.');
      if (value.length > 9) value = SpliceStr(value, value.length - 9, 0, '.');
      if (value.length > 6) value = SpliceStr(value, value.length - 6, 0, '/');
      if (value.length > 2) value = SpliceStr(value, value.length - 2, 0, '-');
    } else {
      if (value.length > 8) value = SpliceStr(value, value.length - 8, 0, '.');
      if (value.length > 5) value = SpliceStr(value, value.length - 5, 0, '.');
      if (value.length > 2) value = SpliceStr(value, value.length - 2, 0, '-');
    }
    return value;
  };

  const send = () => {
    const erros = validate();
    if (Object.keys(erros).length === 0) {
      setSending(true);
      const uri = cad ? '/cadastro' : '/meus-dados';
      fetch(`${process.env.REACT_APP_API}${uri}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(values)
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'success') {
          if (cad) navigate('/login', { replace: true });
          else {
            funcs.setToken({ user: json.user, token });
            navigate('/dashboard', { replace: true });
          }
        } else {
          setSending(false);
          if (json.field) setErrors({ [json.field]: json.error });
          console.log('Erro ao salvar dados!', json.field, json.error);
        }
      }).catch((error) => {
        setSending(false);
        console.log(`Erro de conexão: ${error.message}`);
      });
    }
  };

  return (
    <form
      autoComplete="off"
      {...props}
    >
      <Card>
        <CardHeader
          subheader={cad ? 'Novo usuário' : 'Edição'}
          title={cad ? 'Cadastro' : 'Meus Dados'}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                error={errors?.document}
                helperText={errors?.document}
                label="CPF ou CNPJ"
                name="document"
                onChange={handleChange}
                disabled={!cad}
                readonly={!cad}
                required={cad}
                value={maskCpfCnpj(values?.document)}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Senha"
                name="senha"
                error={errors?.senha}
                helperText={errors?.senha || cad ? errors?.senha : 'Digite apenas caso queira alterar'}
                type="password"
                onChange={handleChange}
                value={values?.senha}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Repetir Senha"
                name="senha2"
                error={errors?.senha2}
                helperText={errors?.senha2}
                type="password"
                onChange={handleChange}
                value={values?.senha2}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome Completo"
                name="nome_completo"
                error={errors?.nome_completo}
                helperText={errors?.nome_completo}
                onChange={handleChange}
                required
                value={values?.nome_completo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                type="email"
                error={errors?.email}
                helperText={errors?.email}
                onChange={handleChange}
                required
                value={values?.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome Fantasia"
                name="nome_fantasia"
                onChange={handleChange}
                value={values?.nome_fantasia}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Razão Social"
                name="razao_social"
                onChange={handleChange}
                value={values?.razao_social}
                variant="outlined"
              />
            </Grid>

          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={send}
            disabled={sending}
          >
            Enviar
          </Button>
        </Box>
      </Card>
    </form>
  );
};

AccountProfileDetails.propTypes = {
  user: PropTypes.object,
  token: PropTypes.string,
  cad: PropTypes.bool,
  funcs: PropTypes.object.isRequired
};

export default AccountProfileDetails;
