import PropTypes from 'prop-types';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';

const Login = ({ setToken }) => {
  const navigate = useNavigate();

  const splice = (str, start, delCount, newSubStr) => str.slice(0, start) + newSubStr + str.slice(start + Math.abs(delCount));

  const maskDocString = (val) => {
    let value = val;
    if (value.length > 11) {
      if (value.length > 15) value = value.substring(0, 15);
      if (value.length > 12) value = splice(value, value.length - 12, 0, '.');
      if (value.length > 9) value = splice(value, value.length - 9, 0, '.');
      if (value.length > 6) value = splice(value, value.length - 6, 0, '/');
      if (value.length > 2) value = splice(value, value.length - 2, 0, '-');
    } else {
      if (value.length > 8) value = splice(value, value.length - 8, 0, '.');
      if (value.length > 5) value = splice(value, value.length - 5, 0, '.');
      if (value.length > 2) value = splice(value, value.length - 2, 0, '-');
    }
    return value;
  };

  const maskDoc = (e) => {
    const el = e.target;
    const value = el.value.replace(/[^0-9]+/g, '');
    el.value = maskDocString(value);
    return e;
  };

  return (
    <>
      <Helmet>
        <title>Login | ADN</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              document: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              document: Yup.string().matches(/([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/, 'CPF/CNPJ incompleto').required('CPF/CNPJ é obrigatório'),
              password: Yup.string().max(255).required('Senha é obrigatória')
            })}
            onSubmit={(values, actions) => {
              // console.log(actions);
              const data = values;
              data.document = data.document.replace(/[^0-9]+/g, '');
              fetch(`${process.env.REACT_APP_API}/login`, {
                method: 'POST',
                mode: 'cors',
                cache: 'default',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
              }).then((response) => response.json()).then((json) => {
                if (json.status === 'success') {
                  setToken(json);
                  navigate('/dashboard', { replace: true });
                } else {
                  actions.setSubmitting(false);
                  actions.setFieldError(json.field, json.error);
                }
              }).catch((error) => {
                actions.setSubmitting(false);
                actions.setErrors({ api: `Erro de conexão: ${error.message}` });
                console.log(`Erro de conexão: ${error.message}`);
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Login
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.document && errors.document)}
                  fullWidth
                  helperText={touched.document && errors.document}
                  label="CPF / CNPJ"
                  margin="normal"
                  name="document"
                  onBlur={handleBlur}
                  onChange={(e) => { handleChange(maskDoc(e)); }}
                  type="document"
                  value={values.document}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Entrar
                  </Button>
                </Box>
                <Box sx={{ py: 4, textAlign: 'center', ':hover': { opacity: 0.8 } }}>
                  <Link to="/cadastro" style={{ fontFamily: 'Arial' }}>
                    <Typography
                      color="textSecondary"
                      variant="text"
                    >
                      Teste grátis por 30 dias
                    </Typography>
                  </Link>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Login;
