import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

const ProductListToolbar = ({ onSearch, ...props }) => (
  <Box {...props}>
    <Box>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Procurar por nome ou telefone"
              variant="outlined"
              onChange={onSearch}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

ProductListToolbar.propTypes = {
  onSearch: PropTypes.func
};

export default ProductListToolbar;
