import PropTypes from 'prop-types';

import Indice from './Indice';

const Naveg = ({
  page, show, next, last, goto, toggleInd
}) => (
  <div>
    {page > 1 ? <hr /> : ''}
    <div className="Naveg">
      <button type="button" className="btn-last" onClick={(e) => { if (page > 1) last(e); }} disabled={(page === 1)}>VOLTAR</button>
      <Indice show={show} goto={goto} click={toggleInd} />
      <div>
        <span>
          Passo
          &#0;
          {page}
          &#0;
          de 8
        </span>
        <button type="button" className="btn-next" onClick={next}>AVANÇAR</button>
      </div>
    </div>
  </div>
);

Naveg.propTypes = {
  page: PropTypes.number,
  show: PropTypes.any,
  next: PropTypes.func,
  last: PropTypes.func,
  goto: PropTypes.func,
  toggleInd: PropTypes.func
};

export default Naveg;
