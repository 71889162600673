import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Pagination
} from '@material-ui/core';
import ProductListToolbar from 'src/components/product/ProductListToolbar';
import ProductCard from 'src/components/product//ProductCard';

const ProductList = ({ funcs }) => {
  const [adns, setAdns] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const token = funcs.getToken();
  const user = funcs.getToken(true);
  const controller = new AbortController();
  const { signal } = controller;
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/adn?page=${page}&search=${search}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      signal
    }).then((response) => response.json()).then((json) => {
      if (json.status === 'success') {
        setAdns(json.adns);
      } else {
        console.log(json);
      }
    }).catch((error) => {
      console.log(`Erro de conexão: ${error.message}`);
    });
    return () => {
      controller.abort();
    };
  }, [token, page, search]);
  const changePage = (evt, pg) => {
    setPage(pg);
  };
  const onSearch = (e) => {
    controller.abort();
    setSearch(e.target.value);
  };
  return (
    <>
      <Helmet>
        <title>ADN | Cotações</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ProductListToolbar onSearch={onSearch} />
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              {adns?.data?.map((product) => (
                <Grid
                  item
                  key={product.id}
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <ProductCard product={product} user={user} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color="primary"
              count={adns.last_page}
              size="small"
              page={page}
              onChange={changePage}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

ProductList.propTypes = {
  funcs: PropTypes.object
};

export default ProductList;
